/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const HungaryFlag: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <svg
          version='1.1'
          id='Capa_1'
          xmlns='http://www.w3.org/2000/svg'
          x='0px'
          y='0px'
          viewBox='0 0 512 512'
          css={cx(className)}
        >
          <rect y='85.337' style={{ fill: '#F0F0F0' }} width='512' height='341.326' />
          <rect y='85.337' style={{ fill: '#D80027' }} width='512' height='113.775' />
          <rect y='312.888' style={{ fill: '#6DA544' }} width='512' height='113.775' />
        </svg>
      )}
    </ClassNames>
  );
};

export default HungaryFlag;
