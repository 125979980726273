import { CancelablePromiseType } from '@sprinx/after-razzle';
import { SearchResultsData } from 'types/SearchResults';
import { SupportedLocale, SupportedRegion } from 'types/Supported';
import apiClient from './appApi';

export default {
  search,
};

function search(params: {
  locale: SupportedLocale;
  region: SupportedRegion;
  searchText: string;
}): CancelablePromiseType<SearchResultsData> {
  return apiClient.get<SearchResultsData, { locale: SupportedLocale; region: SupportedRegion }>(
    '/:region/:locale/search',
    params,
  );
}
