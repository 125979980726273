/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import Container from 'components/Container';
import React from 'react';
import { Helmet } from 'react-helmet';

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
  pageTitle: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ breadcrumbs, className, children, pageTitle, ...rest }) => {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <div
          {...rest}
          css={cx(
            css({
              '& > div:not(:first-of-type)': {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              },
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }),
            className,
          )}
        >
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Container maxWidth='lg'>{breadcrumbs}</Container>
          <div>{children}</div>
        </div>
      )}
    </ClassNames>
  );
};

Page.displayName = 'Page';

export default Page;
