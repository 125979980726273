/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import { messagesIntl } from 'i18n';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { LayoutData } from 'types/LayoutData';
import { SupportedLocale } from 'types/Supported';
import MainLayoutFooter from './components/MainLayoutFooter';
import MainLayoutHeader from './components/MainLayoutHeader';

export interface MainLayoutProps extends React.HTMLAttributes<HTMLDivElement>, LayoutData {
  children: React.ReactNode;
  locale: SupportedLocale;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, className, contact, footer, locale }) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <IntlProvider locale={locale} messages={messagesIntl[locale]}>
          <div
            css={{
              alignItems: 'stretch',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              minHeight: '100vh',
              overflow: 'hidden',
            }}
          >
            <MainLayoutHeader contact={contact} />
            <main
              css={cx(
                css({
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  justifyContent: 'flex-start',
                }),
                className,
              )}
            >
              {children}
            </main>
            <MainLayoutFooter contact={contact} {...footer} />
          </div>
        </IntlProvider>
      )}
    </ClassNames>
  );
};

MainLayout.displayName = 'MainLayout';

export default MainLayout;
