/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import ButtonAnchor from 'components/ButtonAnchor';
import HamburgerButton from 'components/HamburgerButton';
import NavigationItems from 'components/NavigationItems';
import useLocalizationContext from 'hooks/useLocalizationContext';
import useMediaQuery from 'hooks/useMediaQuery';
import Logo from 'icons/Logo';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import { Contact } from 'types/ContactData';

export interface LayoutNavigationProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  contact: Contact;
}

const LayoutNavigation: React.FC<LayoutNavigationProps> = ({ color = 'rgba(0,0,0,.87)', contact }) => {
  const [fixedNavi, setFixedNavi] = React.useState(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const innerRef = React.useRef<any>(null);
  const dummyRef = React.useRef<any>(null);
  const { region, locale } = useLocalizationContext();
  const mobile = useMediaQuery('@media(min-width:960px)');

  const handleCheckPosition = React.useCallback(() => {
    const windowPosition = window.pageYOffset;
    const dummyPosition = mobile
      ? dummyRef.current?.offsetTop + innerRef.current?.offsetHeight
      : dummyRef.current?.offsetTop;

    if (dummyPosition <= windowPosition) {
      setFixedNavi(true);
    } else if (dummyPosition > windowPosition) {
      if (fixedNavi) {
        setFixedNavi(false);
      }
    }
  }, [fixedNavi, mobile]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleCheckPosition);
      return () => {
        window.removeEventListener('scroll', handleCheckPosition);
      };
    }
    return undefined;
  }, [handleCheckPosition]);

  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            '@media(min-width: 425px)': { height: fixedNavi ? 65 : 'auto' },
            '@media(min-width: 960px)': { height: fixedNavi ? 111 : 'auto' },
            height: fixedNavi ? 41 : 'auto',
            width: '100%',
          }}
          ref={dummyRef}
        >
          <div
            css={{
              '@media(min-width: 425px)': {
                padding: fixedNavi ? theme.spacing(1, 1.5) : theme.spacing(1, 0.5),
              },
              '@media(min-width: 600px)': { padding: fixedNavi ? theme.spacing(1, 3.5) : theme.spacing(1, 0.5) },
              '@media(min-width: 960px)': {
                borderRadius: fixedNavi ? '0 0 6px 6px' : 6,
                padding: fixedNavi ? theme.spacing(0, 1) : theme.spacing(0),
              },
              '@media(min-width: 1190px)': {
                left: fixedNavi ? '50%' : 'auto',
                right: 'auto',
                transform: fixedNavi ? 'translate(-50%, 0)' : 'unset',
                width: `calc(${theme.breakpoints.values.lg} - 24px)`,
              },
              background: fixedNavi ? theme.palette.primary.dark : 'transparent',
              boxShadow: fixedNavi ? theme.shadows[3] : 'none',
              display: 'flex',
              justifyContent: 'center',
              left: fixedNavi ? 0 : 'auto',
              padding: fixedNavi ? theme.spacing(0.5, 1) : theme.spacing(0.5, 0),
              position: fixedNavi ? 'fixed' : 'relative',
              right: fixedNavi ? 0 : 'auto',
              top: fixedNavi ? 0 : 'auto',
              zIndex: 11,
            }}
          >
            <div
              css={{
                '@media(min-width: 960px)': {
                  alignItems: fixedNavi ? 'center' : 'flex-start',
                  flexDirection: fixedNavi ? 'row' : 'column',
                  maxWidth: '100%',
                },
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: 'calc(1280px - 56px)',
                width: '100%',
              }}
            >
              <Link
                css={{ display: 'flex', flexShrink: 0 }}
                to={routeUrl('home', { locale, params: { locale: locale, region: region } })}
                onClick={() => setOpen(false)}
              >
                <Logo
                  css={{
                    '@media(min-width: 425px)': { height: 45, width: 150 },
                    '@media(min-width: 960px)': {
                      flexShrink: 0,
                      height: fixedNavi ? 36 : 45,
                      width: fixedNavi ? 120 : 150,
                    },
                    height: 30,
                    width: 100,
                  }}
                  textColor={fixedNavi ? theme.palette.common.white : color}
                />
              </Link>
              <div
                css={{
                  '@media(min-width: 425px)': { marginRight: theme.spacing(3) },
                  '@media(min-width: 600px)': { marginRight: theme.spacing(4) },
                  '@media(min-width: 960px)': {
                    '& > a': { marginLeft: 'auto' },
                    alignItems: 'center',
                    background: fixedNavi ? theme.palette.primary.dark : 'rgba(255,255,255,.85)',
                    border: fixedNavi ? 'none' : '1px solid',
                    borderColor: fixedNavi ? 'none' : theme.palette.divider,
                    borderRadius: fixedNavi ? 0 : 6,
                    display: 'flex',
                    margin: theme.spacing(1, 0),
                    padding: fixedNavi ? 0 : theme.spacing(1, 2),
                    width: '100%',
                  },
                  margin: theme.spacing(0, 1),
                  marginLeft: 'auto',
                  position: 'relative',
                }}
                ref={innerRef}
              >
                <NavigationItems fixedNavi={fixedNavi} open={open} onClose={() => setOpen(false)} contact={contact} />
                <ButtonAnchor
                  href='https://portal.appon.cloud/Account/Login#/'
                  color='secondary'
                  css={{ '@media(min-width: 600px)': { padding: theme.spacing(0.5, 1.5) } }}
                >
                  <FormattedMessage id={'navigation.customerportal'} />
                </ButtonAnchor>
              </div>
              <HamburgerButton color={color} fixedNavi={fixedNavi} open={open} onChange={() => setOpen((ps) => !ps)} />
            </div>
          </div>
        </div>
      )}
    </ClassNames>
  );
};

export default LayoutNavigation;
