/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const LogoSingleColor: React.FC<{ className?: string; textColor?: string }> = ({
  className,
  textColor = 'rgb(0,0,0)',
}) => {
  return (
    <ClassNames>
      {({ cx, css, theme }) => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='944'
          height='304'
          viewBox='0 0 944 304'
          css={cx(css({}), className)}
        >
          <g transform='translate(10 10)'>
            <text fontSize='0' style={{ whiteSpace: 'pre' }}>
              <tspan
                x='286.5'
                y='175.5'
                fontSize='116'
                fontWeight='500'
                style={{
                  fill: textColor,
                  fontFamily: theme.typography.fontFamily,
                  textTransform: 'none',
                  whiteSpace: 'pre',
                }}
                letterSpacing='-1.8'
              >
                AppOn
              </tspan>
            </text>
            <text fontSize='0' style={{ whiteSpace: 'pre' }}>
              <tspan
                x='626.5'
                y='176.5'
                fontSize='116'
                fontWeight='400'
                style={{
                  fill: textColor,
                  fontFamily: theme.typography.fontFamily,
                  textTransform: 'none',
                  whiteSpace: 'pre',
                }}
                letterSpacing='-2.2'
              >
                .cloud
              </tspan>
            </text>
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M226.5 69l-51 29.5s35.5 7 42.5 31-23.5 38-23.5 38l32 18.5V69z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M119 195v59.5L220 196l-39.5-23.5s-3.629 1.4185-9.5 2.5-9.5 1-9.5 1-9.15279963 9.87419277-19.5 14.5c-10.34720037 4.62580723-23 4.5-23 4.5z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M0 68.5l44 25s-33.4015 12.0525-38.5 36c-5.0985 23.9475 22 41.5 22 41.5L0 186.5v-118z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M160 106.5l-41 24v53s8.567 1.062 20-4.5 17-13.5 17-13.5 52.5-4.5 52.5-28.5-48.5-30.5-48.5-30.5z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M57 101.5l50.5 29v52s-4.51128991-.44597064-11-4-10-7.5-10-7.5S31 176.5 18 145s39-43.5 39-43.5z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M39 176.5L5.5 196 107 254.5v-61s-4.584-.101-12-3.5-12-7.5-12-7.5-8.904 1.854-22.5 0-21.5-6-21.5-6z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M68.5 96l44.5 25.5L152.5 99s-8-26-39.5-27.5S68.5 96 68.5 96z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M119 .5l102.5 59-59.5 34s-4.713-12.2665-18-22c-13.287-9.7335-25-9.5-25-9.5V.5z'
            />
            <path
              style={{ fill: textColor }}
              fillRule='evenodd'
              d='M107.5 0L5.5 59l54 31.5s5.168-12.539 20-21.5 28-7.5 28-7.5V0z'
            />
          </g>
        </svg>
      )}
    </ClassNames>
  );
};

export default LogoSingleColor;
