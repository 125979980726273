/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { EmotionCache } from '@emotion/cache';
import { CacheProvider, ClassNames, css, Global, jsx, ThemeProvider } from '@emotion/react';
import DaxlineProBold from 'fonts/DaxlinePro-Bold.ttf';
import DaxlineProBoldWoff from 'fonts/DaxlinePro-Bold.woff';
import DaxlineProLight from 'fonts/DaxlinePro-Light.ttf';
import DaxlineProLightWoff from 'fonts/DaxlinePro-Light.woff';
import DaxlineProMedium from 'fonts/DaxlinePro-Medium.ttf';
import DaxlineProMediumWoff from 'fonts/DaxlinePro-Medium.woff';
import DaxlineProRegular from 'fonts/DaxlinePro-Regular.ttf';
import DaxlineProRegularWoff from 'fonts/DaxlinePro-Regular.woff';
import React from 'react';
import { Helmet } from 'react-helmet';
import theme from 'theme';

export interface AppProps {
  children: React.ReactElement;
  emotionCache: EmotionCache;
}

const App: React.FC<AppProps> = ({ children, emotionCache }) => {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <ClassNames>
          {({ theme }) => (
            <>
              <Global
                styles={css`
                  body {
                    color: ${theme.palette.text.primary};
                    font-family: ${theme.typography.fontFamily};
                    font-size: 1rem;
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    font-family: DaxlinePro, Fallback, sans-serif;

                    svg {
                      font-family: DaxlinePro, Fallback, sans-serif;
                    }

                    @font-face {
                      font-family: DaxlinePro;
                      font-style: normal;
                      font-weight: 400;
                      font-display: swap;
                      src: url('${DaxlineProLight}');
                      src: url('${DaxlineProLightWoff}') format('woff'),
                           url('${DaxlineProLight}') format('truetype');
                    }

                    @font-face {
                      font-family: DaxlinePro;
                      font-style: normal;
                      font-weight: 500;
                      font-display: swap;
                      src: url('${DaxlineProRegular}');
                      src: url('${DaxlineProRegularWoff}') format('woff'),
                           url('${DaxlineProRegular}') format('truetype');
                    }

                    @font-face {
                      font-family: DaxlinePro;
                      font-style: normal;
                      font-weight: 600;
                      font-display: swap;
                      src: url('${DaxlineProMedium}');
                      src: url('${DaxlineProMediumWoff}') format('woff'),
                           url('${DaxlineProMedium}') format('truetype');
                    }

                    @font-face {
                      font-family: DaxlinePro;
                      font-style: normal;
                      font-weight: 700;
                      font-display: swap;
                      src: url('${DaxlineProBold}');
                      src: url('${DaxlineProBoldWoff}') format('woff'),
                           url('${DaxlineProBold}') format('truetype');
                    }
                `}
              />
            </>
          )}
        </ClassNames>
        <Helmet titleTemplate='%s - AppOnCloud' defaultTitle='AppOnCloud' />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

App.displayName = 'App';

export default App;
