/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import searchApi from 'api/searchApi';
import TextField from 'components/TextField';
import useLocalizationContext from 'hooks/useLocalizationContext';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { routeUrl } from 'routesBuilder';
import { SearchResultsData } from 'types/SearchResults';

export type SearchField = React.HTMLAttributes<HTMLDivElement>;

const SearchField: React.FC<SearchField> = () => {
  const { locale, region } = useLocalizationContext();
  const [results, setResults] = React.useState<undefined | SearchResultsData>(undefined);
  const [searchText, setSearchText] = React.useState<undefined | string>(undefined);
  const handleSearch = React.useCallback(
    (value: string | undefined) => {
      setSearchText(value);
      if (typeof value === undefined || !value || value.length < 3) {
        setResults(undefined);
      } else {
        searchApi.search({ locale, region, searchText: value }).then(setResults);
      }
    },
    [locale, region],
  );

  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{ ...theme.typography.body1, display: 'flex', flexDirection: 'column', maxWidth: 600, width: '100%' }}
        >
          <div css={{ ...theme.typography.h2, color: theme.palette.common.white }}>
            <FormattedMessage id='search.label' />
          </div>
          <div css={{ alignItems: 'stretch', display: 'flex', justifyContent: 'center' }}>
            <TextField
              name='search'
              onChange={handleSearch}
              value={searchText}
              css={{ margin: 0 }}
              autoComplete='off'
            />
          </div>
          <SearchResults results={results} />
        </div>
      )}
    </ClassNames>
  );
};

export default SearchField;

const SearchResults: React.FC<{ results: SearchResultsData | undefined }> = ({ results }) => {
  const { locale, region } = useLocalizationContext();
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            '& > a': {
              '&:hover': {
                background: theme.palette.grey[300],
                cursor: 'pointer',
              },
              color: theme.palette.text.primary,
              padding: theme.spacing(1),
              textDecoration: 'none',
              transition: 'background .2s ease',
            },
            background: theme.palette.background,
            borderRadius: 6,
            display: 'flex',
            flexDirection: 'column',
            fontSize: 14,
            height: results ? 350 : 0,
            margin: '0 1px',
            maxHeight: results ? '70vh' : 'auto',
            overflowY: 'auto',
            padding: results ? theme.spacing(1) : 0,
            transition: 'all .3s ease',
            width: 'calc(100% - 2px)',
          }}
        >
          {typeof results !== 'undefined' &&
            results.map((r, idx) => (
              // <Link
              //   to={routeUrl(r.routeType, {
              //     locale,
              //     params: { id: r.path, locale: locale, region: region },
              //   })}
              //   key={idx.toString()}
              // >
              //   {r.title}
              // </Link>
              <a
                href={routeUrl(r.routeType, {
                  locale,
                  params: { id: r.path, locale: locale, region: region },
                })}
                key={idx.toString()}
              >
                {r.title}
              </a>
            ))}
        </div>
      )}
    </ClassNames>
  );
};
