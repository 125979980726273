/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';
import { FieldError } from 'react-hook-form';

export interface TextFieldProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  autoComplete?: string;
  error?: FieldError | undefined;
  label?: string;
  name?: string;
  onChange?: (value: string | undefined) => void;
  placeholder?: string;
  type?: string;
  value: string | undefined;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, placeholder, label, name, value, type, error, onChange, autoComplete }, ref) => {
    const handleChange = React.useCallback(
      (e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      },
      [onChange],
    );
    return (
      <ClassNames>
        {({ cx, css, theme }) => (
          <>
            <div
              className={cx(
                css({
                  '& > input:focus + label': {
                    background: 'transparent',
                    color: error ? theme.palette.error.main : theme.palette.text.secondary,
                    fontSize: 10,
                    left: 2,
                    top: '-4px',
                    transform: 'none',
                  },
                  '& > label': {
                    background: 'transparent',
                    color: error
                      ? theme.palette.error.main
                      : value && value.length > 0
                      ? theme.palette.text.secondary
                      : theme.palette.text.secondary,
                    fontSize: value && value.length > 0 ? 10 : 14,
                    left: value && value.length > 0 ? 2 : theme.spacing(1.5),
                    top: value && value.length > 0 ? '-4px' : '50%',
                    transform: value && value.length > 0 ? 'none' : 'translate(0,-50%)',
                  },
                  border: '1px solid',
                  borderColor: error ? theme.palette.error.main : 'transparent',
                  display: 'flex',
                  margin: theme.spacing(0.5, 0),
                  maxWidth: '100%',
                  position: 'relative',
                  width: '100%',
                }),
                className,
              )}
            >
              <input
                name={name}
                id={name}
                placeholder={placeholder}
                ref={ref}
                type={type}
                css={{
                  '&::placeholder': {
                    color: theme.palette.text.secondary,
                    fontSize: 14,
                  },
                  border: 'none',
                  borderRadius: 3,
                  color: theme.palette.text.primary,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 14,
                  outline: 'none',
                  padding: theme.spacing(1.25, 2),
                  transition: 'all .2s ease',
                  width: '100%',
                }}
                onChange={handleChange}
                autoComplete={autoComplete}
              />
              <label
                htmlFor={name}
                css={(theme) => ({
                  padding: theme.spacing(0, 0.5),
                  position: 'absolute',
                  transition: 'all .2s ease',
                })}
              >
                {label}
              </label>
            </div>
            {error && (
              <div css={{ color: theme.palette.error.main, marginBottom: theme.spacing(1), marginTop: '-8px' }}>
                {error.type === 'required' ? `${label} is required` : error.message}
              </div>
            )}
          </>
        )}
      </ClassNames>
    );
  },
);

export default TextField;
