/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import ButtonIcon from 'components/ButtonIcon';
import CzechFlag from 'icons/CzechFlag';
import HungaryFlag from 'icons/HungaryFlag';
import SlovakiaFlag from 'icons/SlovakiaFlag';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { supportedLocales } from 'regionalSettings';
import { routeUrl } from 'routesBuilder';
import { SupportedRegion } from 'types/Supported';

const locales = {
  cs: 'Česky',
  en: 'English',
  hu: 'Magyar',
  ru: 'Русский',
  sk: 'Slovensky',
};

export interface RegionLanguageStepperProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  onClose: () => void;
}

const RegionLanguageStepper: React.FC<RegionLanguageStepperProps> = ({ onClose, className }) => {
  const [newRegion, setNewRegion] = React.useState<SupportedRegion | undefined>(undefined);
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <div css={cx(css({ display: 'flex', flexDirection: 'column' }), className)}>
          <div css={{ ...theme.typography.h2, color: theme.palette.common.white }}>
            {newRegion ? (
              <FormattedMessage id={'langswitcher.lang'} />
            ) : (
              <FormattedMessage id={'langswitcher.country'} />
            )}
          </div>
          {newRegion ? (
            <SupportedLanguageNavigation onClose={onClose} region={newRegion} />
          ) : (
            <SupportedRegionNavigation onChange={setNewRegion} />
          )}
        </div>
      )}
    </ClassNames>
  );
};

export default RegionLanguageStepper;

RegionLanguageStepper.displayName = 'RegionLanguageStepper';

const NavigatiContainer: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ className, children }) => {
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <div
          css={cx(
            css({
              '& > a': {
                color: theme.palette.common.white,
                display: 'flex',
                fontSize: 18,
                margin: theme.spacing(2),
                textDecoration: 'none',
              },
              '& > button': {},
              '& svg': { height: 32, width: 32 },
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              minHeight: 56,
            }),
            className,
          )}
        >
          {children}
        </div>
      )}
    </ClassNames>
  );
};

NavigatiContainer.displayName = 'NavigatiContainer';

const SupportedRegionNavigation: React.FC<{ onChange: (region: SupportedRegion) => void }> = ({ onChange }) => {
  const handleChange = React.useCallback(
    (nextRegion: SupportedRegion) => () => {
      onChange(nextRegion);
    },
    [onChange],
  );

  return (
    <ClassNames>
      {() => (
        <NavigatiContainer>
          <ButtonIcon onClick={handleChange('cesko')}>
            <CzechFlag />
          </ButtonIcon>
          <ButtonIcon onClick={handleChange('slovensko')}>
            <SlovakiaFlag />
          </ButtonIcon>
          {/* <ButtonIcon onClick={handleChange('rakousko')}>
            <AustriaFlag />
          </ButtonIcon> */}
          {/* <ButtonIcon onClick={handleChange('cesko')}>
            <UsaFlag />
          </ButtonIcon> */}
          <ButtonIcon onClick={handleChange('madarsko')}>
            <HungaryFlag />
          </ButtonIcon>
          {/* <ButtonIcon onClick={handleChange('polsko')}>
            <PolandFlag />
          </ButtonIcon> */}
        </NavigatiContainer>
      )}
    </ClassNames>
  );
};

const SupportedLanguageNavigation: React.FC<{ onClose: () => void; region: SupportedRegion }> = ({
  onClose: handleClose,
  region,
}) => {
  const wrapStoreToCookies = useCallback(
    (occb: () => void, r: string) => (): void => {
      if (document) {
        document.cookie = `currentLocale=${r};path=/;max-age=${60 * 60 * 24 * 365}`;
      }

      if (occb) {
        occb();
      }
    },
    [],
  );

  return (
    <ClassNames>
      {() => (
        <NavigatiContainer>
          {supportedLocales[region].map((locale) => (
            <Link
              key={locale}
              to={routeUrl('home', { locale, params: { locale, region } })}
              onClick={wrapStoreToCookies(handleClose, routeUrl('home', { locale, params: { locale, region } }))}
            >
              {locales[locale]}
            </Link>
          ))}
        </NavigatiContainer>
      )}
    </ClassNames>
  );
};
