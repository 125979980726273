/* eslint-disable max-len */
const messages = {
  cs: {
    'application.text1': 'Chtěl bych více informací',
    'application.text2': 'Proč bych měl používat AppOn.cloud?',
    'application.text3': 'Další software tohoto výrobce',
    'application.whytouse': 'Proč bych měl používat AppOn.cloud Premium?',
    'btn.casestudy': 'Případová studie',
    'btn.download': 'Ke stažení',
    'btn.send': 'Odeslat',
    'btn.video': 'Video',
    'contactform.formError': 'Nastala chyba při odesílání formuláře',
    'contactform.formSent': 'Vážený zákazníku, děkujeme Vám za odeslání dotazu. Ozveme se Vám co nejdříve.',
    'contactform.gdpr': 'Souhlasím se zpracováním vyplněných údajů v rozsahu a k účelům vymezeným v Souhlasu se ',
    'contactform.gdprError': 'K odeslání je potřeba souhlas.',
    'contactform.gdprLink': 'zpracováním osobních údajů',
    'contactform.mail': 'Email',
    'contactform.message': 'Zpráva',
    'contactform.name': 'Jméno',
    'contactform.phone': 'Telefon',
    'contactform.validation.email': 'Do e-mailové adresy zahrňte znak @. V adrese chybí znak @.',
    'contactform.validation.phone': 'Zadejte prosím telefon v mezinárodním tvaru (+420 XXX XXX XXX)',
    'contacts.form.title': 'Máte dotaz? Napište nám',
    'contacts.sales': 'Kontaktní linka',
    'contacts.techsupport': 'Technická podpora',
    'contacts.title': 'Kontaktujte nás, máme zastoupení v několika zemích Evropy',
    'cookies.preferences': 'Změnit předvolby souborů cookie',
    'downloads.title': 'Soubory ke stažení',
    'footer.sales': 'Kontaktní linka',
    'footer.techsupport': 'Technická podpora',
    'homepage.advantage.title': 'Proč bych měl mít firemní aplikace v cloudu?',
    'homepage.form.title': 'Chtěl bych více informací',
    'homepage.introduction.button': 'Podporované aplikace',
    'homepage.introduction.title': 'Nejpohodlnější hosting vašich aplikací',
    'homepage.support': 'Podporujeme',
    'homepage.supportedapps.title': 'Které aplikace podporujeme?',
    'langswitcher.country': 'Vyberte zemi, ve které se nacházíte',
    'langswitcher.lang': 'Vyberte jazyk',
    'navigation.contacts': 'Kontakty',
    'navigation.customerportal': 'Zákaznický portál',
    'navigation.downloads': 'Ke stažení',
    'navigation.forpress': 'Pro média',
    'navigation.references': 'Reference',
    'navigation.services': 'Služby',
    'navigation.support': 'Podpora ',
    'navigation.supportedapps': 'Podporované aplikace',
    'premium.form.title': 'Máte zájem o AppOn.cloud premium? Napište nám a my Vás brzy kontaktujeme.',
    'premium.promo.cta': 'Více informací',
    'premium.promo.text': 'Ještě více pohodlí pro Vaší firmu',
    'pressrelease.form.title': 'Chtěl bych více informací',
    'pressrelease.text1': 'Seznam článků',
    'pressrelease.text2': 'Seznam tiskových zpráv',
    'pressrelease.title': 'Tiskové zprávy',
    'reference.form.title': 'Řady spokojených zákazníků využívají našich služeb. Připojte se k nim i vy.',
    'reference.nextref': 'Další reference',
    'references.logoReferencesTitle': 'Naši další klienti',
    'references.textReferencesTitle': 'Řekli o nás',
    'search.label': 'Vyhledávání',
    'services.form.title': 'Zaujal Vás provoz tohoto produktu v cloudu? Napište nám a my vás brzy kontaktujeme.',
    'services.status': 'Stav služeb',
    'services.title': 'Podporované služby',
    'supportedapps.subtitle':
      'Vyberte si aplikaci podle vašich potřeb od preferovaných dodavatelů. Nevíte si s výběrem rady? Kontaktujte nás.',
  },
};

export default messages;
