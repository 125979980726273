import { SupportedLocale, SupportedRegion } from 'types/Supported';

export const supportedLocales: Record<SupportedRegion, SupportedLocale[]> = {
  cesko: ['cs', 'ru', 'en'],
  madarsko: ['hu', 'en'],
  polsko: [],
  rakousko: [],
  slovensko: ['sk', 'hu', 'en'],
};

export const supportedLocaleAllOptionsPairs: [SupportedRegion, SupportedLocale][] = Object.entries(
  supportedLocales,
).reduce(
  (a: [SupportedRegion, SupportedLocale][], [r, l]) => [
    ...a,
    ...l.map((x) => [r, x] as [SupportedRegion, SupportedLocale]),
  ],
  [],
);

export const supportedLocaleAllOptions: string[] = Object.entries(supportedLocales).reduce(
  (a: string[], [c, l]) => [...a, ...l.map((x) => `/${c}/${x}/`)],
  [],
);
