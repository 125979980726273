import Page, { PageHeadline } from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import LocalizationProvider from 'providers/LocalizationProvider';
import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import { Contact } from 'types/ContactData';
import { LayoutData } from 'types/LayoutData';
import { SupportedLocale, SupportedRegion } from 'types/Supported';

export interface ErrorPageProps {
  error?: string | Error;
  locale: SupportedLocale;
  region: SupportedRegion;
}

const contact: Contact = {};
const footer: LayoutData['footer'] = {
  applications: [],
  footerMenu: [],
};

const ErrorPage: React.FC<ErrorPageProps> = ({ error, locale, region }) => {
  if (process.env.NODE_ENV !== 'production') {
    if (error) {
      if (error instanceof Error) {
        console.error(error.message, error);
      } else {
        console.error('Internal error:', error);
      }
    }
  }

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 500;
        return (
          <LocalizationProvider locale={locale} region={region}>
            <MainLayout contact={contact} footer={footer} locale={locale}>
              <Page pageTitle='Error occurred...'>
                <PageHeadline>An error occurred...</PageHeadline>
                <div>
                  <RouterLink to={routeUrl('home', { locale: locale, params: { locale: locale, region: region } })}>
                    BACK TO HOME
                  </RouterLink>
                </div>
              </Page>
            </MainLayout>
          </LocalizationProvider>
        );
      }}
    />
  );
};

ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;
