/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { ClassNames, jsx } from '@emotion/react';
import ButtonIcon from 'components/ButtonIcon';
import CloseIcon from 'icons/CloseIcon';
import React from 'react';

export interface DialogProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  onClose: () => void;
}

const Dialog: React.FC<DialogProps> = ({ onClose, children }) => {
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            alignItems: 'center',
            background: theme.palette.header.backgroundMobile,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: 1100,
          }}
        >
          <ButtonIcon css={{ position: 'absolute', right: 8, top: 8, zIndex: 12 }} onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
          {children}
        </div>
      )}
    </ClassNames>
  );
};

Dialog.displayName = 'Dialog';

export default Dialog;
