/* eslint-disable max-len */
const messages = {
  en: {
    'application.text1': 'I am interested',
    'application.text2': 'Why should I use AppOn.cloud?',
    'application.text3': 'Other software from this manufacturer',
    'application.whytouse': 'Premium - Why should I use AppOn.cloud Premium?',
    'btn.casestudy': 'Case study',
    'btn.download': 'Download',
    'btn.send': 'Send',
    'btn.video': 'Video',
    'contactform.formError': 'An error occurred while submitting the form',
    'contactform.formSent':
      'Dear customer, thank you for sending your inquiry. We will contact you as soon as possible.',
    'contactform.gdpr':
      'I agree to the processing of completed data to the extent and for the purposes specified in the Consent to the ',
    'contactform.gdprError': 'required.',
    'contactform.gdprLink': 'processing of personal data',
    'contactform.mail': 'Email',
    'contactform.message': 'Message',
    'contactform.name': 'Name',
    'contactform.phone': 'Phone number',
    'contactform.validation.email':
      'Include the @ character in your email address. The address is missing the @ character.',
    'contactform.validation.phone': 'Please enter an international phone number (+XXX XXX XXX XXX)',
    'contacts.form.title': 'Do you have a question? Write to us',
    'contacts.sales': 'Contact line',
    'contacts.techsupport': 'Support',
    'contacts.title': 'Contact us, we have offices in several European countries',
    'cookies.preferences': 'Change cookie preferences',
    'downloads.title': 'Downloads',
    'footer.sales': 'Contact line',
    'footer.techsupport': 'Support',
    'homepage.advantage.title': 'Why should I have business applications in the cloud?',
    'homepage.form.title': 'Is it worth it?',
    'homepage.introduction.button': 'Supported applications',
    'homepage.introduction.title': 'The most convenient hosting of your applications',
    'homepage.support': 'We support',
    'homepage.supportedapps.title': 'Which applications do we support?',
    'langswitcher.country': 'Select a country',
    'langswitcher.lang': 'Select language',
    'navigation.contacts': 'Contacts',
    'navigation.customerportal': 'Customer portal',
    'navigation.downloads': 'Downloads',
    'navigation.forpress': 'Media',
    'navigation.references': 'References',
    'navigation.services': 'Services',
    'navigation.support': 'Support ',
    'navigation.supportedapps': 'Supported applications',
    'premium.form.title': 'Are you interested in AppOn.cloud premium? Write to us and we will contact you soon.',
    'premium.promo.cta': 'I am interested',
    'premium.promo.text': 'Even more comfort for your company',
    'pressrelease.form.title': 'Is it worth it?',
    'pressrelease.text1': 'List of articles',
    'pressrelease.text2': 'List of press releases',
    'pressrelease.title': 'Press release',
    'reference.form.title': 'Many satisfied customers use our services. Be one of them.',
    'reference.nextref': 'Other references',
    'references.logoReferencesTitle': 'Our clients',
    'references.textReferencesTitle': 'They said about us',
    'search.label': 'Search',
    'services.form.title': 'Are you interested in this product? Write to us and we will contact you soon.',
    'services.status': 'State of services',
    'services.title': 'Supported services',
    'supportedapps.subtitle':
      'Choose the application according to your needs from preferred suppliers. Not sure about choosing a board? Contact us.',
  },
};

export default messages;
