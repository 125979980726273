/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import RegionLanguageStepper from 'components/RegionLanguageStepper';
import SearchField from 'components/SearchField';
import useLocalizationContext from 'hooks/useLocalizationContext';
import useMediaQuery from 'hooks/useMediaQuery';
import RightArrow from 'icons/RightArrow';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import { Contact } from 'types/ContactData';

export interface NavigationItemsProps {
  contact: Contact;
  fixedNavi: boolean;
  onClose: () => void;
  open: boolean;
}

const NavigationItems: React.FC<NavigationItemsProps> = ({
  contact,
  fixedNavi = false,
  open = false,
  onClose: handleClose,
}) => {
  const { region, locale } = useLocalizationContext();
  const intl = useIntl();
  const mobile = useMediaQuery('@media(max-width:425px)');

  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            '& > a': {
              '& > svg': {
                fill: theme.palette.common.white,
                height: 24,
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translate(0, -50%) rotate(90deg)',
                width: 24,
              },
              '&:not(first-of-type)': {
                borderTop: '1px solid',
                borderTopColor: theme.palette.divider,
              },
              color: theme.palette.common.white,
              margin: theme.spacing(0, 1),
              padding: theme.spacing(2),
              position: 'relative',
              textDecoration: 'none',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            },
            '@media(min-width: 425px)': {
              top: fixedNavi ? 61 : 94,
            },
            '@media(min-width: 600px)': {
              top: fixedNavi ? 61 : 100,
            },
            '@media(min-width: 960px)': {
              '& > a': {
                '& > svg': {
                  fill: theme.palette.secondary.main,
                },
                '&:not(first-of-type)': { borderTop: 'none' },
                color: theme.palette.primary.main,
                padding: theme.spacing(0, 3, 0, 0),
                whiteSpace: 'unset',
              },
              background: 'transparent',
              bottom: 'unset',
              display: 'flex',
              flexDirection: 'row',
              left: 'unset',
              paddingLeft: fixedNavi ? theme.spacing(2) : 0,
              position: 'static',
              top: 'unset',
              transition: 'none',
              width: 'auto',
            },
            '@media(min-width: 1080px)': {
              margin: theme.spacing(0, 2),
            },
            background: theme.palette.header.backgroundMobile,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            left: 0,
            overflow: 'hidden',
            position: 'fixed',
            top: 40,
            transition: 'width .2s ease',
            width: open ? '100%' : 0,
          }}
        >
          <Link
            to={routeUrl('applications', { locale, params: { locale: locale, region: region } })}
            onClick={handleClose}
          >
            {intl.formatMessage({
              defaultMessage: 'Podporované aplikace',
              id: 'navigation.supportedapps',
            })}
            <RightArrow />
          </Link>
          <Link to={routeUrl('services', { locale, params: { locale: locale, region: region } })} onClick={handleClose}>
            {intl.formatMessage({
              defaultMessage: 'Služby',
              id: 'navigation.services',
            })}
            <RightArrow />
          </Link>
          <Link
            to={routeUrl('reference', { locale, params: { locale: locale, region: region } })}
            onClick={handleClose}
          >
            {intl.formatMessage({
              defaultMessage: 'Reference',
              id: 'navigation.references',
            })}
            <RightArrow />
          </Link>
          <Link to={routeUrl('contact', { locale, params: { locale: locale, region: region } })} onClick={handleClose}>
            {intl.formatMessage({
              defaultMessage: 'Kontakty',
              id: 'navigation.contacts',
            })}
            <RightArrow />
          </Link>
          <Link to={routeUrl('press', { locale, params: { locale: locale, region: region } })} onClick={handleClose}>
            {intl.formatMessage({
              defaultMessage: 'Pro média',
              id: 'navigation.forpress',
            })}
            <RightArrow />
          </Link>
          <Link to={routeUrl('downloads', { locale, params: { locale: locale, region: region } })} onClick={handleClose}>
            {intl.formatMessage({
              defaultMessage: 'Ke stažení',
              id: 'navigation.downloads',
            })}
            <RightArrow />
          </Link>
          {mobile && <MobileHeaderAppbar contact={contact} />}
          {open && (
            <RegionLanguageStepper
              onClose={handleClose}
              css={{ '@media(min-width: 425px)': { display: 'none' }, justifyContent: 'center' }}
            />
          )}
        </div>
      )}
    </ClassNames>
  );
};

export default NavigationItems;

const MobileHeaderAppbar: React.FC<{
  contact: Contact;
  linkColor?: string;
}> = ({ contact, linkColor = '#fff' }) => {
  const intl = useIntl();
  return (
    <ClassNames>
      {({ theme }) => (
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <div
            css={{
              padding: theme.spacing(1),
            }}
          >
            <SearchField />
          </div>
          {contact && (
            <div
              css={{
                '& a': { color: linkColor, textDecoration: 'none' },
                '& a:hover': { textDecoration: 'underline' },
                '& span': { margin: theme.spacing(1, 0) },
                '@media(min-width: 425px)': { fontSize: 13, margin: theme.spacing(0, 2) },
                alignItems: 'center',
                color: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column-reverse',
                margin: theme.spacing(0, 1),
                paddingBottom: theme.spacing(1),
              }}
            >
              <span>
                <a href={`tel:${contact?.techSupport?.phone}`}>{contact?.techSupport?.phone}</a>
              </span>
              <span>
                {intl.formatMessage({
                  defaultMessage: 'Podpora',
                  id: 'navigation.support',
                })}{' '}
                <a href={`mailto:${contact?.techSupport?.email}`}>{contact?.techSupport?.email}</a>
              </span>
            </div>
          )}
        </div>
      )}
    </ClassNames>
  );
};
