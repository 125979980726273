import { Theme } from '@emotion/react';

const fontFamily = '"DaxlinePro", sans-serif';
const htmlFontSize = 10;
const fontSize = 10;
const fontWeightLight = 400;
const fontWeightRegular = 500;
const fontWeightMedium = 600;
const fontWeightBold = 700;
declare module '@emotion/react' {
  interface Theme {
    breakpoints: {
      values: {
        lg: string;
        md: string;
        sm: string;
        xl: string;
        xs: string;
      };
    };
    darkShadows: string;
    palette: {
      background: string;
      backgroundGradient: string;
      backgroundGradientDark: string;
      common: {
        black: string;
        white: string;
      };
      divider: string;
      error: {
        dark: string;
        light: string;
        main: string;
      };
      footer: {
        background: string;
        backgroundDark: string;
        color: string;
        divider: string;
        textHeadline: string;
        textPrimary: string;
        textSecondary: string;
      };
      grey: {
        [key: number]: string;
      };
      header: {
        background: string;
        backgroundMobile: string;
        textHeadline: string;
        textPrimary: string;
      };
      primary: {
        dark: string;
        main: string;
      };
      secondary: {
        dark: string;
        main: string;
      };
      success: {
        dark: string;
        light: string;
        main: string;
      };
      text: {
        primary: string;
        secondary: string;
      };
      warning: {
        dark: string;
        light: string;
        main: string;
      };
    };
    shadows: string[];
    spacing: typeof spacing;
    typography: {
      body1: any;
      body2: any;
      caption: any;
      fontFamily: string;
      fontSize: number;
      fontWeightBold: number;
      fontWeightLight: number;
      fontWeightMedium: number;
      fontWeightRegular: number;
      h1: any;
      h2: any;
      h3: any;
      htmlFontSize: number;
    };
  }
}

const theme: Theme = {
  breakpoints: {
    values: {
      lg: '1190px',
      md: '960px',
      sm: '600px',
      xl: '1620px',
      xs: '0px',
    },
  },
  darkShadows:
    '0px 5px 5px -3px rgba(255,255,255,0.3),0px 8px 10px 1px rgba(255,255,255,0.14),0px 3px 14px 2px rgba(255,255,255,0.12)',
  palette: {
    background: '#FFFFFF',
    backgroundGradient: 'linear-gradient(to bottom, #fff, #fdfdfd, #f1f1f1 20%)',
    backgroundGradientDark: 'linear-gradient(to bottom, #34627f, #010221)',
    common: {
      black: '#000',
      white: '#fff',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    error: {
      dark: '#d32f2f',
      light: '#e57373',
      main: '#f44336',
    },
    footer: {
      background: '#212121',
      backgroundDark: '#000120',
      color: '#c3c3c3',
      divider: '#333333',
      textHeadline: 'rgba(255,255,255,1)',
      textPrimary: '#666666',
      textSecondary: '#939393',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    header: {
      background: 'rgba(255,255,255,.9)',
      backgroundMobile: 'rgba(11,39,77,.95)',
      textHeadline: 'rgba(255,255,255,1)',
      textPrimary: 'rgba(0,0,0,0.87)',
    },
    primary: {
      dark: '#0b274d',
      main: '#51b8ed',
    },
    secondary: {
      dark: '#327242',
      main: '#9ec141',
    },
    success: {
      dark: '#388e3c',
      light: '#81c784',
      main: '#4caf50',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.54)',
    },
    warning: {
      dark: '#f57c00',
      light: '#ffb74d',
      main: '#ff9800',
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing,
  typography: {
    body1: {
      '@media(min-width: 600px)': { lineHeight: '26px' },
      color: 'rgba(0,0,0,.87)',
      fontSize: '18px',
      letterSpacing: 0.3,
      lineHeight: '18px',
    },
    body2: {
      '@media(min-width: 425px)': {
        fontSize: '12px',
      },
      '@media(min-width: 600px)': {
        fontSize: '14px',
      },
      color: 'rgba(0,0,0,.54)',
      fontSize: '12px',
      letterSpacing: 0.3,
      lineHeight: '18px',
      textAlign: 'justify',
    },
    caption: {
      // '@media(min-width: 425px)': { fontSize: 10 },
      '@media(min-width: 600px)': { fontSize: 12, lineHeight: '16px' },
      '@media(min-width: 960px)': { fontSize: 14, lineHeight: '18px' },
      fontSize: 10,
    },
    fontFamily,
    fontSize,
    fontWeightBold,
    fontWeightLight,
    fontWeightMedium,
    fontWeightRegular,
    h1: {
      '@media(min-width: 425px)': { fontSize: 22 },
      '@media(min-width: 600px)': { fontSize: 28, lineHeight: '32px' },
      '@media(min-width: 960px)': { fontSize: 42, lineHeight: '56px' },
      fontSize: 20,
      fontWeight: fontWeightRegular,
      lineHeight: '28px',
      textAlign: 'center',
    },
    h2: {
      '@media(min-width: 600px)': { fontSize: 18 },
      '@media(min-width: 960px)': { fontSize: 20, lineHeight: '28px', textAlign: 'left' },
      color: '#636779',
      fontSize: 14,
      fontWeight: fontWeightLight,
      letterSpacing: 0.3,
      lineHeight: '24px',
      textAlign: 'center',
    },
    h3: {
      color: '#1e2a4a',
      fontSize: 24,
      letterSpacing: 0.3,
      lineHeight: '24px',
      margin: '16px 0 24px 0',
      textAlign: 'center',
    },
    htmlFontSize,
  },
};

export default theme;

function spacing(a: number): string;
function spacing(a: number, b: number): string;
function spacing(a: number, b: number, c: number, d: number): string;
function spacing(a: number, b?: number, c?: number, d?: number): string {
  if (b !== undefined && c !== undefined && d !== undefined) return `${a * 8}px ${b * 8}px ${c * 8}px ${d * 8}px`;
  if (b !== undefined) return `${a * 8}px ${b * 8}px`;
  return `${a * 8}px`;
}
