/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import ButtonIcon from 'components/ButtonIcon';
import Dialog from 'components/Dialog';
import RegionLanguageStepper from 'components/RegionLanguageStepper';
import SearchField from 'components/SearchField';
import useLocalizationContext from 'hooks/useLocalizationContext';
import CzechFlag from 'icons/CzechFlag';
import HungaryFlag from 'icons/HungaryFlag';
import Search from 'icons/Search';
import SlovakiaFlag from 'icons/SlovakiaFlag';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Contact } from 'types/ContactData';

export interface LayoutHeaderAppbarProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  contact: Contact;
  fSize?: number;
  fWeight?: 'normal' | 'bold';
  linkColor?: string;
}

const flagIconMap = {
  cesko: CzechFlag,
  madarsko: HungaryFlag,
  slovensko: SlovakiaFlag,
};

const LayoutHeaderAppbar: React.FC<LayoutHeaderAppbarProps> = ({
  contact,
  color = '#fff',
  linkColor = '#fff',
  fSize = 13,
  fWeight = 'normal',
}) => {
  const [openLangDialog, setOpenLangDialog] = React.useState<boolean>(false);
  const [openSearchDialog, setOpenSearchDialog] = React.useState<boolean>(false);
  const { locale, region } = useLocalizationContext();
  const Flag = flagIconMap[region];

  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            ...theme.typography.body1,
            '@media(min-width: 600px)': {
              fontSize: 13,
            },
            alignItems: 'center',
            display: 'flex',
            fontSize: 8,
            justifyContent: 'flex-end',
            padding: theme.spacing(0.5, 0),
          }}
        >
          <ButtonIcon
            onClick={() => setOpenSearchDialog(true)}
            css={{
              '& > svg': {
                fill: color,
                height: 20,
                width: 20,
              },
            }}
          >
            <Search />
          </ButtonIcon>
          {contact && (
            <div
              css={{
                '& a': { color: linkColor, textDecoration: 'none' },
                '& a:hover': { textDecoration: 'underline' },
                '@media(min-width: 425px)': { fontSize: fSize, margin: theme.spacing(0, 2) },
                alignItems: 'flex-end',
                color: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                fontWeight: fWeight,
                margin: theme.spacing(0, 1),
              }}
            >
              <span>
                <a href={`tel:${contact?.techSupport?.phone}`}>{contact?.techSupport?.phone}</a>
              </span>
              <span>
                <FormattedMessage id={'navigation.support'} />
                <a href={`mailto:${contact?.techSupport?.email}`}>{contact?.techSupport?.email}</a>
              </span>
            </div>
          )}
          <ButtonIcon
            onClick={() => setOpenLangDialog(true)}
            css={{
              color: color,
            }}
          >
            <Flag /> <span css={{ display: 'block', marginLeft: theme.spacing(1) }}>{locale.toUpperCase()}</span>
          </ButtonIcon>
          {openLangDialog && (
            <Dialog onClose={() => setOpenLangDialog(false)}>
              <RegionLanguageStepper onClose={() => setOpenLangDialog(false)} />
            </Dialog>
          )}
          {openSearchDialog && (
            <Dialog onClose={() => setOpenSearchDialog(false)}>
              <SearchField />
            </Dialog>
          )}
        </div>
      )}
    </ClassNames>
  );
};

export default LayoutHeaderAppbar;
