import React from 'react';

export interface PageHeadlineProps extends React.HTMLAttributes<HTMLDivElement> {
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const PageHeadline = React.forwardRef<HTMLDivElement, PageHeadlineProps>(
  ({ actions, className, children, ...rest }, ref) => {
    return (
      <div ref={ref} className={className} {...rest}>
        <h1>{children}</h1>
        {actions && <div>{actions}</div>}
      </div>
    );
  },
);

PageHeadline.displayName = 'PageHeadline';

export default PageHeadline;
