/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  flexDirection?: 'column' | 'row';
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | number;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, flexDirection = 'row', maxWidth = 'lg', className }, ref) => {
    return (
      <ClassNames>
        {({ cx, theme, css }) => (
          <div
            ref={ref}
            css={cx(
              css({
                '@media(min-width: 600px)': { padding: theme.spacing(0, 3) },
                display: 'flex',
                flexDirection: flexDirection,
                maxWidth: typeof maxWidth === 'number' ? maxWidth : theme.breakpoints.values[maxWidth],
                padding: theme.spacing(0, 1),
                width: '100%',
              }),
              className,
            )}
          >
            {children}
          </div>
        )}
      </ClassNames>
    );
  },
);

Container.displayName = 'Container';

export default Container;
