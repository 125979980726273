/** @jsx jsx */
import { ClassNames, jsx, Theme } from '@emotion/react';
import Container from 'components/Container';
import useLocalizationContext from 'hooks/useLocalizationContext';
import LogoSingleColor from 'icons/LogoSingleColor';
import footerSprinxLogo from 'images/footer-sprinx-logo.webp';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import theme from 'theme';
import { Contact } from 'types/ContactData';
import { FooterData } from 'types/LayoutData';
import { NavigationLink } from 'types/NavigationLink';

export interface MainLayoutFooterProps extends React.HTMLAttributes<HTMLDivElement>, FooterData {
  background?: 'dark' | 'light';
  contact: Contact;
}

const MainLayoutFooter: React.FC<MainLayoutFooterProps> = ({
  applications,
  contact,
  footerMenu,
  background = 'light',
}) => {
  return (
    <div
      css={(theme: Theme) => ({
        alignItems: 'center',
        background: background === 'dark' ? theme.palette.footer.backgroundDark : theme.palette.footer.background,
        color: background === 'dark' ? theme.palette.common.white : theme.palette.footer.textPrimary,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 0),
      })}
    >
      <Container
        css={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
        maxWidth='lg'
      >
        <div css={{ width: '100%' }}>
          <div
            css={{
              '@media(min-width: 960px)': {
                alignItems: 'flex-start',
                borderBottom: '1px solid',
                borderBottomColor: theme.palette.footer.divider,
                flexDirection: 'row',
                flexGrow: 1,
                paddingBottom: theme.spacing(2),
              },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LogoSingleColor
              textColor={theme.palette.common.white}
              css={{
                '@media(min-width: 425px)': {
                  width: 150,
                },
                fill: theme.palette.common.white,
                height: 'auto',
                width: 100,
              }}
            />
            <MainLayoutFooterContact contact={contact} />
          </div>
          <div
            css={{
              '@media(min-width: 960px)': {
                flexDirection: 'row',
                flexGrow: 1,
              },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MainLayoutFooterSupport applications={applications} footerMenu={footerMenu} />
            <div
              css={{
                '& > img': { width: 60 },
                '@media(min-width: 960px)': {
                  '& > img': { width: 90 },
                  alignItems: 'flex-start',
                  padding: theme.spacing(4, 6),
                },
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <img src={footerSprinxLogo} alt='Sprinx logo' />
              <div
                css={{
                  cursor: 'pointer',
                  marginTop: 'auto',
                }}
              >
                <span css={{ '&:hover': { textDecoration: 'underline' } }} id='open_preferences_center'>
                  <FormattedMessage id={'cookies.preferences'} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

MainLayoutFooter.displayName = 'MainLayoutFooter';

export default MainLayoutFooter;

const MainLayoutFooterContact: React.FC<{ contact: Contact }> = ({ contact }) => {
  return (
    <ClassNames>
      {() => (
        <div
          css={{
            ...theme.typography.body2,
            '@media(min-width: 960px)': { borderBottom: 'none', flexGrow: 1, paddingLeft: theme.spacing(3) },
            alignItems: 'flex-start',
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.footer.divider,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          <MainLayoutFooterContactColumn>
            <div css={{ color: theme.palette.footer.color, marginBottom: theme.spacing(2) }}>
              <FormattedMessage id={'footer.sales'} />
            </div>
            {contact.sales?.phone && <a href={`tel:${contact.sales?.phone}`}>{contact.sales?.phone}</a>}
            {contact.sales?.email && <a href={`mailto:${contact.sales?.email}`}>{contact.sales?.email}</a>}
          </MainLayoutFooterContactColumn>
          <MainLayoutFooterContactColumn>
            <div css={{ color: theme.palette.footer.color, marginBottom: theme.spacing(2) }}>
              <FormattedMessage id={'footer.techsupport'} />
            </div>
            {contact.techSupport?.phone && (
              <a href={`tel:${contact.techSupport?.phone}`}>{contact.techSupport?.phone}</a>
            )}
            {contact.techSupport?.email && (
              <a href={`mailto:${contact.techSupport?.email}`}>{contact.techSupport?.email}</a>
            )}
          </MainLayoutFooterContactColumn>
          <MainLayoutFooterContactColumn>
            <div css={{ color: theme.palette.footer.color, marginBottom: theme.spacing(2) }}>
              {contact.company?.name}
            </div>
            <p>
              {contact.company?.street}
              <br />
              {contact.company?.city}
              <br />
              {contact.company?.country}
            </p>
          </MainLayoutFooterContactColumn>
          {/* {[contact.sales].map((c, idx) => (
            <div
              key={idx.toString()}
              css={{
                '& > a': {
                  color: theme.palette.footer.color,
                  textDecoration: 'none',
                },
                '& > a:hover': {
                  textDecoration: 'underline',
                },
                '&:nth-of-type(1)': { paddingLeft: 0 },
                '&:nth-of-type(2)': { paddingRight: 0 },
                '&:nth-of-type(3)': { paddingLeft: 0 },
                '@media(min-width: 600px)': {
                  '&:nth-of-type(1)': { paddingRight: theme.spacing(1) },
                  '&:nth-of-type(2)': { padding: theme.spacing(1) },
                  '&:nth-of-type(3)': { paddingLeft: theme.spacing(1), paddingRight: 0 },
                  width: '33%',
                },
                '@media(min-width: 960px)': {
                  '&:nth-of-type(1)': { paddingRight: theme.spacing(4) },
                  '&:nth-of-type(2)': { padding: theme.spacing(1, 4) },
                  '&:nth-of-type(3)': { paddingLeft: theme.spacing(4) },
                  marginTop: theme.spacing(0),
                },
                alignItems: 'flex-start',
                color: theme.palette.footer.color,
                display: 'flex',
                flexDirection: 'column',
                marginTop: theme.spacing(2),
                padding: theme.spacing(1),
                width: '50%',
              }}
            >
              <div css={{ color: theme.palette.footer.color, marginBottom: theme.spacing(2) }}>{c.name}</div>
              {c.phone && <a href={`phone:${c.phone}`}>{c.phone}</a>}
              {c.email && <a href={`mailto:${c.email}`}>{c.email}</a>}
              {c.address && <p>{HtmlParser(c.address)}</p>}
            </div>
          ))} */}
        </div>
      )}
    </ClassNames>
  );
};
MainLayoutFooterContact.displayName = 'MainLayoutFooterContact';

const MainLayoutFooterContactColumn: React.FC = ({ children }) => {
  return (
    <ClassNames>
      {() => (
        <div
          css={{
            '& > a': {
              color: theme.palette.footer.color,
              textDecoration: 'none',
            },
            '& > a:hover': {
              textDecoration: 'underline',
            },
            '&:nth-of-type(1)': { paddingLeft: 0 },
            '&:nth-of-type(2)': { paddingRight: 0 },
            '&:nth-of-type(3)': { paddingLeft: 0 },
            '@media(min-width: 600px)': {
              '&:nth-of-type(1)': { paddingRight: theme.spacing(1) },
              '&:nth-of-type(2)': { padding: theme.spacing(1) },
              '&:nth-of-type(3)': { paddingLeft: theme.spacing(1), paddingRight: 0 },
              width: '33%',
            },
            '@media(min-width: 960px)': {
              '&:nth-of-type(1)': { paddingRight: theme.spacing(4) },
              '&:nth-of-type(2)': { padding: theme.spacing(1, 4) },
              '&:nth-of-type(3)': { paddingLeft: theme.spacing(4) },
              marginTop: theme.spacing(0),
            },
            alignItems: 'flex-start',
            color: theme.palette.footer.color,
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
            width: '50%',
          }}
        >
          {children}
        </div>
      )}
    </ClassNames>
  );
};
MainLayoutFooterContactColumn.displayName = 'MainLayoutFooterContactColumn';

const MainLayoutFooterSupport: React.FC<{ applications: NavigationLink[]; footerMenu: NavigationLink[] }> = ({
  applications,
  footerMenu,
}) => {
  const { locale, region } = useLocalizationContext();
  const intl = useIntl();
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            ...theme.typography.body2,
            '@media(min-width: 960px)': { width: '75%' },
            color: theme.palette.footer.color,
            flexShrink: 0,
            padding: theme.spacing(2, 0),
          }}
        >
          {intl.formatMessage({
            defaultMessage: 'Podporujeme',
            id: 'homepage.support',
          })}

          <div
            css={{
              '@media(min-width: 960px)': { flexDirection: 'row' },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              css={{
                '& > a': {
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  color: theme.palette.footer.color,
                  display: 'block',
                  paddingBottom: 10,
                  textDecoration: 'none',
                  transition: 'all .2s ease',
                },
                '@media(min-width: 600px)': {
                  columnCount: 3,
                  columnGap: 22,
                },
                columnCount: 2,
                columnGap: 28,
                padding: theme.spacing(2, 1.5, 2, 0),
                width: '100%',
              }}
            >
              {(applications || []).map((app, idx) => (
                <Link
                  to={routeUrl('applicationDetail', {
                    locale,
                    params: { id: app?.path, locale: locale, region: region },
                  })}
                  key={idx.toString()}
                >
                  {app.title}
                </Link>
              ))}
            </div>
            <div
              css={{
                '& > a': {
                  '&:hover': { textDecoration: 'underline' },
                  color: theme.palette.footer.color,
                  paddingBottom: 10,
                  textDecoration: 'none',
                },
                '@media(min-width: 960px)': {
                  borderLeft: '1px solid',
                  borderLeftColor: theme.palette.footer.divider,
                  borderRight: '1px solid',
                  borderRightColor: theme.palette.footer.divider,
                  borderTop: 'none',
                  minWidth: 'auto,',
                  padding: theme.spacing(2, 5),
                  width: 400,
                },
                borderTop: '1px solid',
                borderTopColor: theme.palette.footer.divider,
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(2, 0),
                width: '100%',
              }}
            >
              {footerMenu.map((link, idx) => (
                <MainLayoutFooterLink key={idx.toString()} {...link}></MainLayoutFooterLink>
              ))}
            </div>
          </div>
        </div>
      )}
    </ClassNames>
  );
};
MainLayoutFooterSupport.displayName = 'MainLayoutFooterSupport';

const MainLayoutFooterLink: React.FC<NavigationLink> = ({ path, title }) => {
  const { locale, region } = useLocalizationContext();
  return path === 'home' ||
    path === 'contact' ||
    path === 'applications' ||
    path === 'services' ||
    path === 'reference' ||
    path === 'premium' ||
    path === 'press' ? (
    <Link
      to={routeUrl(path, {
        locale,
        params: { locale: locale, region: region },
      })}
    >
      {title}
    </Link>
  ) : (
    <a href={path}>{title}</a>
  );
};
MainLayoutFooterLink.displayName = 'MainLayoutFooterLink';
