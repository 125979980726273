import Page, { PageHeadline } from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import LocalizationProvider from 'providers/LocalizationProvider';
import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import { Contact } from 'types/ContactData';
import { LayoutData } from 'types/LayoutData';
import { SupportedLocale, SupportedRegion } from 'types/Supported';

export interface NotFoundPageProps extends React.HTMLAttributes<HTMLDivElement> {
  locale: SupportedLocale;
  region: SupportedRegion;
}

const contact: Contact = {};
const footer: LayoutData['footer'] = {
  applications: [],
  footerMenu: [],
};

const NotFoundPage: React.FC<NotFoundPageProps> = ({ locale = 'cs', region = 'cesko' }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <LocalizationProvider locale={locale} region={region}>
            <MainLayout contact={contact} footer={footer} locale={locale}>
              <Page pageTitle='Page not found...'>
                <PageHeadline>{'Page not found...'}</PageHeadline>
                <div>
                  <RouterLink to={routeUrl('home', { locale: locale, params: { locale: locale, region: region } })}>
                    BACK TO HOME
                  </RouterLink>
                </div>
              </Page>
            </MainLayout>
          </LocalizationProvider>
        );
      }}
    />
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
