/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface ButtonIconProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
}

const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ children, className, onClick, disabled = false, type = 'button' }, ref) => {
    return (
      <ClassNames>
        {({ theme, cx, css }) => (
          <button
            disabled={disabled}
            onClick={onClick}
            ref={ref}
            type={type}
            css={cx(
              css({
                '& > svg': {
                  fill: theme.palette.common.white,
                  height: 24,
                  width: 24,
                },
                alignItems: 'center',
                background: 'transparent',
                border: 'none',
                borderRadius: 100,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                outline: 'none',
                padding: theme.spacing(1),
                textDecoration: 'none',
                transition: 'all .2s ease',
              }),
              className,
            )}
          >
            {children}
          </button>
        )}
      </ClassNames>
    );
  },
);

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
