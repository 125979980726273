/* eslint-disable max-len */
const messages = {
  ru: {
    'application.text1': 'Стоит ли оно мне?',
    'application.text2': 'Зачем мне использовать AppOn.cloud?',
    'application.text3': 'Другое ПО от этого производителя',
    'application.whytouse': 'Premium - Зачем мне использовать AppOn.cloud Premium?',
    'btn.casestudy': 'тематические исследования',
    'btn.download': 'Скачать',
    'btn.send': 'Отправить',
    'btn.video': 'видео',
    'contactform.formError': 'Ошибка при отправке формы',
    'contactform.formSent':
      'Уважаемый покупатель, благодарим Вас за отправку запроса. Мы свяжемся с вами как можно скорее.',
    'contactform.gdpr': 'Я согласен на обработку заполненных данных в объеме и для целей, указанных в Согласии на ',
    'contactform.gdprError': 'required.',
    'contactform.gdprLink': 'обработку персональных данных',
    'contactform.mail': 'электронная почта',
    'contactform.message': 'сообщение',
    'contactform.name': 'название',
    'contactform.phone': 'Телефон',
    'contactform.validation.email': 'Включите символ @ в свой адрес электронной почты. В адресе отсутствует символ @.',
    'contactform.validation.phone': 'Пожалуйста, введите международный номер телефона (+XXX XXX XXX XXX)',
    'contacts.form.title': 'У вас есть вопрос? напишите нам',
    'contacts.sales': 'контактная линия',
    'contacts.techsupport': 'Техподдержка',
    'contacts.title': 'Свяжитесь с нами, у нас есть офисы в нескольких странах Европы.',
    'cookies.preferences': 'Изменить настройки файлов фа́йлы куки́',
    'downloads.title': 'Загрузки',
    'footer.sales': 'контактная линия',
    'footer.techsupport': 'Техподдержка',
    'homepage.advantage.title': 'Зачем мне размещать бизнес-приложения в облаке?',
    'homepage.form.title': 'Стоит ли оно мне?',
    'homepage.introduction.button': 'Поддерживаемые приложения',
    'homepage.introduction.title': 'Самый удобный хостинг ваших приложений',
    'homepage.support': 'мы поддерживаем',
    'homepage.supportedapps.title': 'Какие приложения мы поддерживаем?',
    'langswitcher.country': 'выберите страну',
    'langswitcher.lang': 'выберите язык',
    'navigation.contacts': 'контакты',
    'navigation.customerportal': 'Портал для клиентов',
    'navigation.downloads': 'Загрузки',
    'navigation.forpress': 'для СМИ',
    'navigation.references': 'Справка',
    'navigation.services': 'Сервисы',
    'navigation.support': 'служба поддержки ',
    'navigation.supportedapps': 'Поддерживаемые приложения',
    'premium.form.title': 'Вас интересует AppOn.cloud Premium? Напишите нам, и мы свяжемся с вами в ближайшее время.',
    'premium.promo.cta': 'Я заинтересован в',
    'premium.promo.text': 'еще больше удобства для вашего бизнеса',
    'pressrelease.form.title': 'Стоит ли оно мне?',
    'pressrelease.text1': 'список статей',
    'pressrelease.text2': 'список пресс-релизов',
    'pressrelease.title': 'пресс-релиз',
    'reference.form.title': 'Нашими услугами пользуются многие довольные клиенты. Будь одним из них.',
    'reference.nextref': 'другие ссылки',
    'references.logoReferencesTitle': 'Наши клиенты',
    'references.textReferencesTitle': 'Они сказали о нас',
    'search.label': 'Поиск',
    'services.form.title': 'Вы заинтересованы в этом продукте? Напишите нам, и мы свяжемся с вами в ближайшее время.',
    'services.status': 'Cостояние услуг',
    'services.title': 'Поддерживаемые услуги',
    'supportedapps.subtitle':
      'Выберите приложение от предпочтительных поставщиков в соответствии с вашими потребностями. Не уверены в выборе доски? Связаться с нами.',
  },
};

export default messages;
