/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import DoubleRightArrow from 'icons/DoubleRightArrow';
import React from 'react';
import theme from 'theme';

export interface ButtonAnchorProps {
  children: React.ReactNode;
  className?: string;
  color?: 'primary' | 'secondary' | 'default';
  href: string;
}

const bgOptions = {
  default: theme.palette.divider,
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
};

const ButtonAnchor = React.forwardRef<HTMLAnchorElement, ButtonAnchorProps>(
  ({ children, className, href = '/', color = 'primary', ...rest }, ref) => {
    return (
      <ClassNames>
        {({ theme, cx, css }) => (
          <a
            href={href}
            target='_blank'
            rel='noreferrer'
            ref={ref}
            css={cx(
              css({
                '& > svg': {
                  '@media(min-width: 425px)': {
                    height: 10,
                    width: 10,
                  },
                  fill: theme.palette.common.white,
                  height: 6,
                  marginLeft: theme.spacing(0.5),
                  width: 6,
                },
                '&:hover': {
                  boxShadow: theme.shadows[6],
                },
                '@media(min-width: 425px)': { fontSize: 14 },
                '@media(min-width: 600px)': { fontSize: 16, padding: theme.spacing(1, 1.5) },
                alignItems: 'center',
                background: bgOptions[color],
                border: 'none',
                borderRadius: 3,
                boxShadow: theme.shadows[3],
                color: color === 'default' ? theme.palette.text.primary : theme.palette.common.white,
                cursor: 'pointer',
                display: 'flex',
                fontSize: 10,
                justifyContent: 'center',
                padding: theme.spacing(0.5, 1.5),
                textDecoration: 'none',
                transition: 'all .2s ease',
              }),
              className,
            )}
            {...rest}
          >
            {children}
            <DoubleRightArrow />
          </a>
        )}
      </ClassNames>
    );
  },
);

ButtonAnchor.displayName = 'ButtonAnchor';

export default ButtonAnchor;
