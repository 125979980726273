/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';
import Container from 'components/Container';
import LayoutHeaderAppbar from 'layouts/components/LayoutHeaderAppbar';
import LayoutNavigation from 'layouts/components/LayoutNavigation';
import React from 'react';
import theme from 'theme';
import { Contact } from 'types/ContactData';

export interface MainLayoutHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  contact: Contact;
}

const MainLayoutHeader: React.FC<MainLayoutHeaderProps> = ({ contact }) => {
  return (
    <div
      css={(theme) => ({
        alignItems: 'center',
        color: theme.palette.header.textPrimary,
        display: 'flex',
        flexBasis: '100%',
        flexDirection: 'column',
        flexShrink: 0,
        justifyContent: 'flex-start',
      })}
    >
      <Container
        css={{ '@media(min-width: 425px)': { display: 'flex', justifyContent: 'flex-end' }, display: 'none' }}
        maxWidth='xl'
      >
        <LayoutHeaderAppbar
          contact={contact}
          color={theme.palette.text.secondary}
          linkColor={theme.palette.text.primary}
        />
      </Container>
      <Container css={{ justifyContent: 'center' }} maxWidth='lg'>
        <LayoutNavigation color={theme.palette.text.primary} contact={contact} />
      </Container>
    </div>
  );
};

MainLayoutHeader.displayName = 'MainLayoutHeader';

export default MainLayoutHeader;
