/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface HamburgerButtonProps {
  className?: string;
  color?: string;
  fixedNavi: boolean;
  onChange: () => void;
  open: boolean;
}

const HamburgerButton: React.FC<HamburgerButtonProps> = ({
  fixedNavi = false,
  open = false,
  onChange: handleChange,
  className,
  color = '#000',
}) => {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <button
          type='button'
          aria-label='hamburger'
          onClick={handleChange}
          css={cx(
            css({
              '@media(min-width: 960px)': {
                display: 'none',
              },
              alignItems: 'center',
              background: 'transparent',
              border: 'none',
              display: 'flex',
              height: 32,
              justifyContent: 'center',
              outline: 'none',
              padding: 0,
              width: 32,
              zIndex: 11,
            }),
            className,
          )}
        >
          <span
            css={{
              '&, &::before, &::after': {
                borderRadius: 3,
                display: 'inline-block',
                height: 3,
                width: 22,
              },
              '&::after': {
                top: open ? 0 : 7,
                transform: open ? 'rotate(-135deg)' : 'none',
              },
              '&::before': {
                top: open ? 0 : '-7px',
                transform: open ? 'rotate(135deg)' : 'none',
              },
              '&::before, &::after': {
                backgroundColor: fixedNavi ? '#fff' : color,
                content: '""',
                left: 0,
                position: 'absolute',
                transition: 'all .2s',
              },
              '@media(min-width: 425px)': {
                '&, &::before, &::after': {
                  height: 3,
                  width: 32,
                },
                '&::after': {
                  top: open ? 0 : 10,
                },
                '&::before': {
                  top: open ? 0 : '-10px',
                },
              },
              backgroundColor: open ? 'transparent' : fixedNavi ? '#fff' : color,
              position: 'relative',
              transition: 'all .2s',
            }}
          >
            &nbsp;
          </span>
        </button>
      )}
    </ClassNames>
  );
};

export default HamburgerButton;
