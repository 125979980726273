/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const CzechFlag: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <svg
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          x='0px'
          y='0px'
          viewBox='0 0 512 512'
          css={cx(className)}
        >
          <path
            style={{ fill: '#41479B' }}
            d='M3.256,421.59L256,256L3.256,90.41C1.311,92.029,0,94.375,0,97.103v317.793
       C0,417.625,1.311,419.971,3.256,421.59z'
          />
          <path
            style={{ fill: '#FF4B55' }}
            d='M3.256,421.59c1.53,1.274,3.425,2.134,5.571,2.134h494.345c4.875,0,8.828-3.953,8.828-8.828V256H256
       L3.256,421.59z'
          />
          <path
            style={{ fill: '#F5F5F5' }}
            d='M3.256,90.41c1.53-1.274,3.425-2.134,5.571-2.134h494.345c4.875,0,8.828,3.953,8.828,8.828V256H256
       L3.256,90.41z'
          />
        </svg>
      )}
    </ClassNames>
  );
};

export default CzechFlag;
