import React from 'react';
import { SupportedLocale, SupportedRegion } from 'types/Supported';
import LocalizationContext from './LocalizationContext';

const LocalizationProvider: React.FC<{ locale: SupportedLocale; region: SupportedRegion }> = ({
  children,
  locale = 'cs',
  region = 'cesko',
}) => {
  return (
    <LocalizationContext.Provider
      value={{
        locale,
        region,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export default LocalizationProvider;
